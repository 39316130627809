<template>
  <baidu-map
    :center="center"
    :zoom="zoom"
    @zoomend="zoomFun"
    :scroll-wheel-zoom="true"
    style="width: 100%; height: 100%; position: relative"
    @click="handleIconClick"
  >
    <div style="height: 40px" class="flex autocompelete">
      <el-autocomplete
        popper-class="my-autocomplete"
        placeholder="搜企业"
        v-model="keyword"
        :fetch-suggestions="querySearch"
        @select="handleSelect"
      >
        <i
          class="el-icon-error el-input__icon"
          slot="suffix"
          style="fontsize: 24px"
          @click="handleIconClick"
          v-if="keyword"
        >
        </i>
        <template slot-scope="{ item }">
          <div class="flex">
            <img
              src="../../assets/images/home/search.png"
              class="search_img mt_9"
            />
            <div class="name">{{ item.name }}</div>
            <div class="addr">{{ item.city }}{{ item.area }}</div>
          </div>
        </template>
      </el-autocomplete>
    </div>

    <div
      class="flex autocompelete autocompelete-right"
      @click="handleIconClick"
    >
      <div
        class="text-center cursor-pointer"
        :class="item.clicked ? 'checkedStyle' : 'unchecked'"
        v-for="(item, index) in typeList"
        :key="index"
        @click.stop="chooseType(item)"
      >
        {{ item.label }}
      </div>

      <div class="searchInfo" v-if="info.name" @click.stop="handleNative()">
        <div class="fz_20 plr_20 font_blod">{{ info.name }}</div>
        <el-divider></el-divider>
        <div class="flex plr_20">
          <img src="../../assets/images/home/local.png" class="local_img" />
          <div class="fz_14 ml_10">
            {{ info.province }}{{ info.city }}{{ info.area }}{{ info.address }}
          </div>
        </div>
        <el-divider></el-divider>
        <div class="flex plr_20">
          <div class="left_icon"></div>
          <div class="fz_14 ml_10">服务情况</div>
        </div>
        <div class="plr_20 bg_num mt_15">
          <img src="../../assets/images/home/num.png" class="num_img" />
          <div>
            <div class="fc_333333 fz_16 font_blod">{{ info.count }}人</div>
            <div class="fz_12 fc_666666 mt_15">服务人数</div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="flex plr_20">
          <div class="left_icon"></div>
          <div class="fz_14 ml_10">公司实景</div>
        </div>
        <div class="plr_20 mt_15 flex imageList">
          <img
            :src="item"
            class="info_img"
            v-for="(item, index) in info.imgs"
            :key="index"
            @click="handleToDetail(item)"
          />
        </div>
      </div>
    </div>

    <bm-map-type
      :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']"
      anchor="BMAP_ANCHOR_TOP_LEFT"
    ></bm-map-type>
    <!-- 标签 -->
    <!-- <bm-label
        content="惠山经济开发区"
        v-if="showLable"
        :position="{ lng: 120.32666102, lat: 31.68065298 }"
        :labelStyle="labelStyle" />
      <bm-label
        content="惠山工业转型集聚区"
        v-if="showLable"
        :position="{ lng: 120.215349, lat: 31.712548 }"
        :labelStyle="labelStyle"
    /> -->
    <!-- <bm-label content="惠山经济开发区管委会" :position="{ lng: 120.29666102, lat: 31.67865298 }" :labelStyle="labelStyle" /> -->

    <!-- 行政区划 -->
    <!-- <bm-boundary name="无锡市惠山区" :strokeWeight="4" strokeColor="blue" fillColor="" fillOpacity=""></bm-boundary> -->
    <!-- 多边形 -->
    <bm-polygon
      :path="polygonPath11"
      stroke-color="#7D92E6"
      fillColor="#6F8DC8"
      fillOpacity="0.2"
      :stroke-opacity="1"
      :stroke-weight="4"
    />
    <bm-polygon
      :path="polygonPath22"
      stroke-color="#7D92E6"
      fillColor="#6F8DC8"
      fillOpacity="0.2"
      :stroke-opacity="1"
      :stroke-weight="4"
    />
    <bm-polygon
      :path="polygonPath33"
      stroke-color="#7D92E6"
      fillColor="#6F8DC8"
      fillOpacity="0.2"
      :stroke-opacity="1"
      :stroke-weight="4"
    />
    <bm-polygon
      :path="polygonPath44"
      stroke-color="#7D92E6"
      fillColor="#6F8DC8"
      fillOpacity="0.2"
      :stroke-opacity="1"
      :stroke-weight="4"
    />

    <!-- 热力图 -->
    <bml-heatmap :data="mapList" :max="100" :radius="20" v-if="showMap">
    </bml-heatmap>
    <!-- 标记点 -->
    <bm-marker
      v-else
      v-for="(item, index) in markerList"
      :key="index"
      :position="{ lng: item.lng, lat: item.lat }"
      :dragging="true"
      @click="getwindowInfo(item)"
      :icon="{ url: item.hotmarkImg, size: item.size }"
    >
    </bm-marker>
    <bm-marker
      :position="{ lng: 120.32666102, lat: 31.68065298 }"
      :icon="{ url: hotmarkImg, size: { width: 0, height: 0 } }"
    >
      <bm-label
        content="惠山经济开发区"
        v-if="showLable"
        :labelStyle="labelStyle"
      />
    </bm-marker>
    <bm-marker
      :position="{ lng: 120.215349, lat: 31.712548 }"
      :icon="{ url: hotmarkImg, size: { width: 0, height: 0 } }"
    >
      <bm-label
        content="惠山工业转型集聚区"
        v-if="showLable"
        :labelStyle="labelStyle"
      />
    </bm-marker>
    <bm-marker
      :position="{ lng: 120.285515, lat: 31.648503 }"
      :icon="{ url: hotmarkImg, size: { width: 0, height: 0 } }"
    >
      <bm-label content="堰桥街道" v-if="showLable" :labelStyle="labelStyle" />
    </bm-marker>
    <bm-marker
      :position="{ lng: 120.165382, lat: 31.718219 }"
      :icon="{ url: hotmarkImg, size: { width: 0, height: 0 } }"
    >
      <bm-label content="玉祁街道" v-if="showLable" :labelStyle="labelStyle" />
    </bm-marker>
    <!-- <bm-marker
      :position="{ lng: info.lng, lat: info.lat }"
      :dragging="true"
      animation="BMAP_ANIMATION_BOUNCE"
    >
      <bm-label
        content="我爱北京天安门"
        :labelStyle="{ color: 'red', fontSize: '24px' }"
        :offset="{ width: -35, height: 30 }"
      />
    </bm-marker> -->
    <!-- 信息窗体 -->
    <!-- <bm-info-window :position="windowPoint" :show="show" @close="infoWindowClose" @open="infoWindowOpen">
			<div class="mt_10">{{ companyName }}</div>
			<div class="mt_20">服务人数：{{ companyCount }}</div>
		</bm-info-window> -->

    <el-dialog
      :visible.sync="imgViewDlgVisible"
      :modal-append-to-body="false"
      width="550px"
    >
      <img width="100%" :src="ImgUrl" alt="" />
    </el-dialog>
  </baidu-map>
</template>

<script>
import {
  polygonPath1,
  polygonPath2,
  polygonPath3,
  polygonPath4,
} from "./polygon2";
import { BmlHeatmap } from "vue-baidu-map"; //引人
import {
  getHot,
  // getPageByName,
  getHotOne,
} from "@/api/home";
export default {
  components: {
    //注册
    BmlHeatmap,
  },
  data() {
    return {
      center: {
        lng: 120.330219,
        lat: 31.68196,
      },
      //模拟的经纬度及热力值数据；lng：经度 lat: 纬度 count：热力值
      mapList: [],
      polygonPath1: [],
      polygonPath2: [],
      polygonPath3: [],
      polygonPath4: [],
      showLable: true,
      labelStyle: {
        color: "#717885",
        fontSize: "24px",
        border: "none",
        backgroundColor: "transparent",
        opacity: "0.7",
      },

      polygonPath11: [],
      polygonPath22: [],
      polygonPath33: [],
      polygonPath44: [],
      windowPoint: {
        lng: "",
        lat: "",
      },
      show: false,
      companyName: "",
      companyCount: "",
      keyword: "",
      location: "",
      value: [],
      list: [],
      typeList: [
        {
          value: "1",
          label: "热力图",
          clicked: true,
          labelStyle: {
            borderRadious: "4px 0 0 4px",
          },
        },
        {
          value: "2",
          label: "点位图",
          clicked: false,
          labelStyle: {
            borderRadious: "0 4px 4px 0",
          },
        },
      ],
      showMap: true,
      hotmarkImg: require("@/assets/images/home/hotmark.png"),
      restaurants: [],
      state: "",
      info: {},
      markerList: [],
      imgViewDlgVisible: false,
      ImgUrl: "",
      size: {
        width: 20,
        height: 25,
      },
      showSearch: false,
      zoom: "13",
    };
  },

  mounted() {
    // map.setMapStyle({ style: 'grayscale' });
    this.getHotInfo();
    this.polygonPath1 = polygonPath1;
    this.polygonPath2 = polygonPath2;
    this.polygonPath3 = polygonPath3;
    this.polygonPath4 = polygonPath4;
    this.polygonPath1.forEach((item) => {
      this.polygonPath11.push({
        lng: item.lng + 0.011,
        lat: item.lat + 0.0035,
      });
    });
    this.polygonPath2.forEach((item) => {
      this.polygonPath22.push({
        lng: item.lng + 0.011,
        lat: item.lat + 0.0035,
      });
    });
    this.polygonPath3.forEach((item) => {
      this.polygonPath33.push({
        lng: item.lng + 0.011,
        lat: item.lat + 0.0035,
      });
    });
    this.polygonPath4.forEach((item) => {
      this.polygonPath44.push({
        lng: item.lng + 0.011,
        lat: item.lat + 0.0035,
      });
    });
  },
  methods: {
    getHotInfo() {
      getHot({
        img: 1,
      }).then((res) => {
        this.mapList = res.data;
        this.markerList = res.data;
        this.markerList.forEach((item) => {
          item.hotmarkImg = require("@/assets/images/home/hotmark.png");
          item.size = {
            width: 20,
            height: 25,
          };
        });
      });
    },
    // 多边形--惠山经济开发区
    zoomFun(e) {
      this.zoom = e.target.getZoom();
      if (e.target.getZoom() < 12) {
        this.showLable = false;
      } else {
        this.showLable = true;
        this.labelStyle.fontSize = e.target.getZoom() * 2 + "px";
      }
    },
    // 标记点信息窗体
    getwindowInfo(e) {
      //   this.windowPoint.lng = e.lng;
      //   this.windowPoint.lat = e.lat;
      //   this.companyName = e.name;
      //   this.companyCount = e.count;
      //   this.show = true;
      const that = this;
      if (that.markerList.length == "1") {
        that.id = that.markerList[0].id;
        that.handleToOne(that.markerList[0]);
      } else {
        that.id = e.id;
        that.handleToOne(e);
      }
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    // 右上角切换图类型
    chooseType(item) {
      const that = this;
      that.typeList.map((val) => {
        val.clicked = false;
      });
      that.typeList.map((val) => {
        if (val.label == item.label) {
          that.$set(item, "clicked", !item.clicked);
          if (item.value == "1") {
            that.showMap = true;
          } else {
            that.showMap = false;
          }
        }
      });
    },
    querySearch(queryString, cb) {
      getHot({
        img: 1,
        name: queryString,
      }).then((res) => {
        this.mapList = res.data;
        var results = this.mapList;
        cb(results);
      });
    },
    handleSelect(item) {
      this.showMap = false;
      this.typeList[0].clicked = false;
      this.typeList[1].clicked = true;
      this.keyword = item.name;
      this.id = item.id;
      this.showSearch = true;
      this.handleToOne();
    },
    handleIconClick() {
      this.keyword = "";
      this.markerList = JSON.parse(JSON.stringify(this.mapList));
      this.center = {
        lng: 120.330219,
        lat: 31.68196,
      };
      this.showSearch = false;
      this.info = "";
      this.markerList.forEach((item) => {
        item.hotmarkImg = require("@/assets/images/home/hotmark.png");
        item.size = {
          width: 20,
          height: 25,
        };
      });
      //   this.hotmarkImg = require('@/assets/images/home/hotmark.png');
      //   this.size = { width: 20, height: 25 };
    },
    handleToOne(tag) {
      getHotOne({
        id: this.id,
      }).then((res) => {
        this.info = res.data;

        if (!tag) {
          this.markerList = [];
          this.markerList.push(this.info);
          this.center = {
            lng: this.info.lng,
            lat: this.info.lat,
          };
          this.markerList[0].hotmarkImg = require("@/assets/images/position.gif");
          this.markerList[0].size = {
            width: 48,
            height: 80,
          };
          //   this.hotmarkImg = require('@/assets/images/position.gif');
          //   this.size = { width: 48, height: 54 };
        } else {
          this.markerList.forEach((item) => {
            if (item.id == this.id) {
              item.hotmarkImg = require("@/assets/images/position.gif");
              item.size = {
                width: 48,
                height: 80,
              };
              // this.center = {
              // 	lng: item.lng,
              // 	lat: item.lat
              // };
            } else {
              item.hotmarkImg = require("@/assets/images/home/hotmark.png");
              item.size = {
                width: 20,
                height: 25,
              };
            }
          });
        }
      });
    },
    // 百度地图初始化,改变地图背景色
    // handler({
    // 	map
    // }) {
    // 	//百度地图主题默认为白色，如上图所示
    // 	//百度地图使用黑色主题
    // 	//[百度地图更多主题](https://lbsyun.baidu.com/custom/list.htm)
    // 	var mapStyle = {
    // 		style: 'grayscale'
    // 	};
    // 	map.setMapStyle(mapStyle);
    // },
    //预览图片
    handleToDetail(tag) {
      this.ImgUrl = tag;
      this.imgViewDlgVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/*地图标题*/
.anchorBL {
  display: none;
}

::v-deep .BMap_bubble_title {
  color: black;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
}

::v-deep .BMap_pop div:nth-child(1) {
  border-radius: 7px 0 0 0;
}

::v-deep .BMap_pop div:nth-child(3) {
  border-radius: 0 7px 0 0;
  /* background:#ABABAB;; */
  /*background: #ABABAB;*/
  width: 23px;
  width: 0px;
  height: 0px;
}

::v-deep .BMap_pop div:nth-child(3) div {
  border-radius: 7px;
}

::v-deep .BMap_pop div:nth-child(5) {
  border-radius: 0 0 0 7px;
}

::v-deep .BMap_pop div:nth-child(5) div {
  border-radius: 7px;
}

::v-deep .BMap_pop div:nth-child(7) {
  border-radius: 0 0 7px 0;
}

::v-deep .BMap_pop div:nth-child(7) div {
  border-radius: 7px;
}

::v-deep .el-select {
  width: 400px;
}

.search {
  position: absolute;
  top: 60px;
  width: 100%;
  padding: 20px 10px;
  box-sizing: border-box;
}

.autocompelete {
  position: absolute;
  top: 60px;
  padding: 20px 10px;

  &-right {
    right: 20px;
  }
}

.unchecked {
  font-size: 14px;
  color: #333333;
  background-color: #ffffff;
  width: 100px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 1px 8px 0px rgba(151, 151, 151, 0.4);
}

.checkedStyle {
  font-size: 14px;
  color: #ffffff;
  background-color: #459bff;
  width: 100px;
  height: 40px;
  line-height: 40px;
  box-shadow: 0px 1px 8px 0px rgba(151, 151, 151, 0.4);
}

::v-deep .el-select {
  background-color: #ffffff;
}

::v-deep .el-input__inner {
  // font-size: 18px;
  border: none;
}

.my-autocomplete {
  li {
    line-height: normal;
    padding: 7px;

    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      line-height: 40px;
      margin-left: 15px;
    }

    .addr {
      font-size: 14px;
      color: #999999;
      line-height: 40px;
      margin-left: 15px;
    }

    .highlighted .addr {
      color: #ddd;
    }
  }
}

::v-deep .el-input__suffix {
  border-left: 1px solid;
  margin: 10px 0;
  height: -webkit-fill-available;
}

::v-deep .el-input__icon {
  line-height: 26px;
  margin: 0 20px;
}

::v-deep .el-input {
  width: 398px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 0px rgba(151, 151, 151, 0.4);
  border-radius: 4px 0px 0px 4px;
}

.searchBtn {
  width: 80px !important;
  height: 40px !important;
  background: #459bff;
  box-shadow: 0px 1px 8px 0px rgba(151, 151, 151, 0.4);
  border-radius: 0px 4px 4px 0px;
  font-weight: 600;
  padding: 10px !important;
}

.search_icon {
  width: 22px;
  height: 20px;
}

.search_img {
  width: 18px;
  height: 20px;
}

.mt_9 {
  margin-top: 9px;
}

.searchInfo {
  width: 456px;
  // height: 446px;
  padding: 20px 0;
  box-sizing: border-box;
  background: #f6f8fa;
  box-shadow: 0px 1px 13px 0px rgba(151, 151, 151, 0.4);
  border-radius: 3px;
  margin-left: 16px;
}

.local_img {
  width: 12px;
  height: 14px;
  margin-top: 3px;
}

::v-deep .el-divider--horizontal {
  margin: 20px 0;
}

.plr_20 {
  padding: 0 20px;
}

.left_icon {
  width: 4px;
  height: 14px;
  background: #459bff;
  border-radius: 2px;
  margin-top: 3px;
}

.bg_num {
  width: 128px;
  height: 85px;
  background: #edf0f3;
  border-radius: 2px;
  margin-left: 20px;
  position: relative;
  padding: 24px 0 0 24px;
}

.mt_15 {
  margin-top: 15px;
}

.num_img {
  width: 40px;
  height: 36px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.info_img {
  width: 128px;
  height: 85px;
  margin-right: 15px;
}

.imageList {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  // height: 300rpx;
  display: inline;
  float: left;
  box-sizing: border-box;
}

// ::v-deep .BMap_Marker {
//   display: none;
// }
</style>
