<template>
  <div class="bg_FFFFFF">
    <Header :activeName="activeName" />
    <!-- <div class="flex justify-center">
      <div class="fixed_width">
        <div class="" style="height:900px">
          <Hotmap />
        </div>
      </div>
    </div> -->
    <div class="" style="height:900px">
      <Hotmap />
    </div>
    <Bottom />
  </div>
</template>

<script>
import Header from '@/components/header/index';
import Bottom from '@/components/bottom/index';
import Hotmap from '@/components/hotmap/index';
export default {
  components: {
    Header,
    Bottom,
    Hotmap
  },
  data() {
    return {
      activeName: '服务热力图',
    };
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/home.scss';
</style>
